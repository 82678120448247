import React from "react"
import Layout from "../../components/global/Layout"

const BlivAnnoncoer = () => {
  return (
    <Layout
      metaTitle="Bliv annoncør"
      metaDescription="Bliv annoncør på Opholdsguide"
      slug="/information/bliv-annoncoer/"
    >
      <div className="container my-xl">
        <section className="text-content">
          <h1>Bliv annoncør</h1>
          <p>
            Hvis du ønsker at flere mennesker skal se og opleve din kro, dit
            hotel eller noget helt tredje, hører vi meget gerne fra dig.
            Opholdsguide.dk er en side, som hjælper danskerne med at finde de
            bedste ophold i Danmark. Derfor er det måske oplagt for dig og din
            forretning, at få vist dit opholdssted her på siden. Vi hører meget
            gerne fra dig på e-mail. Derefter kan vi sammen finde ud af, om det
            er noget, der skal gøres mere ved, og hvordan vi gør det.
          </p>
          <p>
            Med andre ord har folk i hotelbranchen altså en unik mulighed for at
            få reklame for sit hotel, som ikke bare indebærer Google Adwords og
            Facebook annoncering. Vi tilbyder noget mere, og vi beskriver oven i
            købet dit hotel på en positiv måde, så folk får lyst til at booke på
            lige netop dit hotel.
          </p>

          <p>Vi bruger primært søgemaskineoptimering til markedsføring.</p>
          <p>
            Vi bestræber os på at besvare alle henvendelser inden for 24 timer.
          </p>
        </section>
      </div>
    </Layout>
  )
}

export default BlivAnnoncoer
